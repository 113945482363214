@import "../../themes.scss";

.alert-message {
    .snackbar-alert {
        margin-top: 10px;
        border-radius: 20px;

        .alert-success {
            border-radius: 20px;
            background-color: #00C9A7;
            color: #fff;
        }
        .alert-error {
            border-radius: 20px;
            background-color: #FF6F91;
            color: #fff;
        }
    }
}
