@import "../themes.scss";

.reset-container {
  width: 26px;
  height: 26px;
  border-radius: 8px;
  padding: auto;
  .reset-icon {
    color: rgb(58, 98, 233);
    font-size: 27px !important;
  }
}

.assign-container {
  color: $primary-color;
  width: 25px;
  height: 25px;
  padding: auto;
  .assign-icon {
    font-size: 27px !important;
  }
}

.update-container {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: $background-dollar !important;

  .update-icon {
    color: #fff;
    margin-bottom: 2px;
    font-size: 14px !important;
  }
}

.delete-container {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: #fff !important;
  border: 1px solid $background-delete;
  .delete-icon {
    color: $background-delete;
    margin-bottom: 3px;
    font-size: 15px !important;
  }
}

.delete-icon {
  color: $background-delete;
  font-size: 26px !important;
}

.btn-void {
  height: 28px !important;
  border-radius: 5px !important;
  background-color: $background-delete !important;
  color: #fff !important;
  padding: 2px 10px !important;
  font-family: $font-body;
  font-size: 13px;
}

.update-title {
  font-family: $font-title;
  font-size: $font-size-body !important;
}
