@import "../../themes.scss";

.crop-container {
    .MuiPaper-root {
        border-radius: 10px !important;
    }

    .dialog-title {
        font-size: $font-size-body;
        color: $primary-color;
        font-weight: bold;
    }

    .close-icon {
        color: red;
    }

    .avater-image {
        width: 140px;
        height: 140px;
    }

    .dialog-content {
        background: #333;
        position: relative;
        height: 500px;
        width: auto;
    }
}