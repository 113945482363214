.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: Century;
  src: url("./Assets/font/Century.ttf");
}

@font-face {
  font-family: KhmerOsMuol;
  src: url("./Assets/font/KhmerOSmuollight.ttf");
}

@font-face {
  font-family: Siemreap;
  src: url("./Assets/font/KhmerOSsiemreap.ttf");
}

@font-face {
  font-family: Bayon;
  src: url("./Assets/font/Bayon.ttf");
}

/* ======================================== report style ===================================== */

.report-top-title {
  text-align: center !important;
  font-size: 18px !important;
  color: #000 !important;
  font-family: Century !important;
}

.report-top-subtitle {
  padding-bottom: 10px;
  text-align: center !important;
  font-size: 15px !important;
  color: #000 !important;
  font-family: Century !important;
}

.report-container-print {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
}

.report-container {
  width: 100% !important;
  border-collapse: collapse;
  padding: 10px 20px;
}

.report-container tr th {
  background-color: #027816 !important;
  color: #fff !important;
  border: none !important;
  text-transform: uppercase !important;
  font-family: Century !important;
  font-size: 14px !important;
}

.report-container tr td {
  font-size: 14px !important;
  font-family: Century !important;
}

.thead-account {
  width: 60% !important;
  padding: 8px !important;
  text-align: left !important;
}

.thead-month-balancesheet {
  width: 20% !important;
  padding: 8px 0px !important;
  text-align: left !important;
}

/* ======================== Income Statement ======================== */
.thead-account-statement {
  width: 60% !important;
  padding: 8px !important;
  text-align: left !important;
}
.thead-current-month {
  width: 20% !important;
  padding: 8px 0px !important;
  text-align: left !important;
}
.thead-year-toyear {
  width: 20% !important;
  padding: 8px 0px !important;
  text-align: left !important;
}
/* ======================== Income Statement ======================== */
.thead-account-ledger {
  width: 40% !important;
  padding: 8px !important;
  text-align: left !important;
}
.thead-memo {
  width: 30% !important;
  padding: 8px 0px !important;
  text-align: left !important;
}
.thead-debit {
  width: 15% !important;
  padding: 8px 0px !important;
  text-align: left !important;
}

.report-body-row:nth-child(even) {
  background-color: #f1f3f1 !important;
  border: none !important;
}
.report-body-cell-header {
  padding: 8px 0px 8px 8px !important;
  /* font-weight: bold !important; */
}
.report-body-cell-sub1 {
  font-family: Century !important;
  font-size: 14px !important;
  font-weight: bold !important;
  /* padding: 8px 0px 8px 20px !important; */
  border: none !important;
  color: #000 !important;
}
.report-body-cell-sub1:nth-child(even) {
  background-color: #f1f3f1 !important;
  border: none !important;
}
/* ======================== Statement ======================== */
.report-body-cell-statement-sub1 {
  font-family: Century !important;
  font-size: 14px !important;
  /* padding: 8px 0px 8px 20px !important; */
  border: none !important;
  color: #000 !important;
}
.report-body-cell-statement-sub1:nth-child(even) {
  background-color: #f1f3f1 !important;
  border: none !important;
}
.report-body-cell {
  font-family: Century !important;
  padding: 8px 0px !important;
  font-size: 14px !important;
  border: none !important;
  margin-right: 10px !important;
  color: #000 !important;
}
.report-body-cell:nth-child(event) {
  background-color: #f1f3f1 !important;
  border: none !important;
}
.report-body-cell-total {
  font-family: Century !important;
  font-size: 14px !important;
  /* font-weight: bold !important; */
  padding: 8px 0px !important;
  border: none !important;
  color: #000 !important;
}
.report-body-cell-sub2 {
  font-family: Century !important;
  /* padding: 8px 0px 8px 40px !important; */
  font-size: 14px !important;
  border: none !important;
  color: #000 !important;
}
.report-body-cell-sub2:nth-child(even) {
  background-color: #f1f3f1 !important;
  border: none !important;
}
.report-body-cell-debit {
  font-family: Century !important;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 8px 0px !important;
  /* border-bottom: 1px solid #027816 !important; */
  color: #027816 !important;
}
