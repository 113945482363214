@import "../../themes.scss";

.dashboard-container {
  width: 100% !important;

  // ================================ first block =============================
  .card-container {
    width: 100% !important;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    padding: 0px 10px;
    .share-des {
      font-size: $font-size-subtitle;
      font-family: $font-moul;
      color: $primary-color;
      font-weight: 100;
    }
    .front-icon {
      width: 80%;
    }
    .share-number {
      font-size: 26px;
      font-weight: bold;
    }
    .icon-money {
      width: 40px;
      height: 40px;
      justify-items: center;
      margin-top: 20px;
      margin-bottom: auto;
      margin-left: 15px;
    }

    .all-sale {
      color: $primary-color;
      padding-top: 5px;
      padding-bottom: 5px;
      justify-content: center;
      font-family: $font-title;
      font-size: $font-size-body;
      color: $primary-color;
      font-weight: 550;
    }
    .amount-number {
      font-family: $font-title;
      font-size: 22px;
      font-weight: bold;
      color: $primary-color;
    }
  }

  // ================================ department chart ===============================
  .account-chart-container {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 20px;

    .chart-title {
      font-family: $font-title;
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }
    .account-chart {
      border: 1px solid rgb(238, 237, 237);
      border-radius: 10px;
      padding: 10px;
      .apexcharts-menu-item {
        text-align: left !important;
        padding: 2px 0px !important;
      }
    }
  }

  //================================== Total case ===========================
  .total-case-bank {
    background-image: url("../../Assets/icon_dashboard/Second_share.png");
    width: 100% !important;
    height: 236px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 97% 92%;
    background-color: #027816e2;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;
    .icon-container {
      background-color: #d3f3bf;
      width: 80px;
      height: 80px;
      .icon-money {
        width: 50px;
        height: 50px;
      }
    }
    .amount-number {
      font-family: $font-title;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #fff;
    }
    .card-des {
      font-family: $font-title;
      text-align: center;
      font-size: $font-size-body;
      color: #fff;
    }
  }

  .total-case-hand {
    background-image: url("../../Assets/icon_dashboard/Second_share.png");
    width: 100% !important;
    height: 236px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: 97% 92%;
    background-color: #286be8c6;
    // background-color: #027816e2;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;
    .icon-container {
      background-color: #d5e4f8;
      width: 80px;
      height: 80px;
      .icon-money {
        width: 50px;
        height: 50px;
      }
    }
    .amount-number {
      font-family: $font-title;
      text-align: center;
      font-size: 25px;
      font-weight: bold;
      color: #fff;
    }
    .card-des {
      font-family: $font-title;
      text-align: center;
      font-size: $font-size-body;
      color: #fff;
    }
  }

  // ============================== Expense table ==============================
  .expense-table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;

    .table-title {
      font-family: $font-title;
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }

    .table {
      width: 100%;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .table-row {
        .header-title-start {
          background-color: #fff;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 5px 10px 5px 20px;
          background-color: $primary-color;
          font-weight: bold;
          color: #fff;
        }

        .header-title {
          background-color: #fff;
          padding: 5px 10px 5px 10px;
          background-color: $primary-color;
          font-weight: bold;
          color: #fff;
        }

        .header-title-end {
          background-color: #fff;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 5px 20px 5px 10px;
          background-color: $primary-color;
          font-weight: bold;
          color: #fff;
        }
      }

      .body-row {
        border-start-end-radius: 5px;
        border-start-start-radius: 5px;

        .body-cell-start {
          background-color: #fff;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 5px 10px 5px 20px;
          border: none;
        }

        .body-cell {
          background-color: #fff;
          padding: 5px 10px 5px 10px;
          border: none;
        }

        .body-cell-end {
          background-color: #fff;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          padding: 5px 20px 5px 10px;
          border: none;
        }
      }
    }
  }
}
