@import "../themes.scss";

.dashboard-page {
  margin-top: 60px;
  padding-bottom: 20px;

  .page-title {
    font-family: $font-title;
    color: gray;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .page-subtitle {
    font-family: $font-title;
    color: gray;
    font-size: 12px;
  }

  .box-container {
    margin-top: 40px;
  }

  .search-title {
    width: 120px !important;
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
    font-weight: bold !important;
  }
  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-title;
    .MuiOutlinedInput-input {
      border-radius: 10px;
      font-family: $font-title;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-title;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-title;
      border-radius: 6px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 6px;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 6px;
    }
  }
}
