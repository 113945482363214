@import "../../themes.scss";

.empty-container {
  .text-title {
    margin-top: 10px;
    font-family: $font-title;
    font-size: $font-size-subtitle;
    font-weight: bold;
    text-align: center;
    color: rgb(182, 181, 181);
  }

  .empty-image{
    width: 20%;
    height: auto;
  }

  .text-des {
    font-family: $font-title;
    font-size: $font-size-body;
    text-align: center;
    color: gray;
  }
}