@import "../../themes.scss";

.top-bar {
  background-color: #fff !important;
  padding: 0px 20px;

  .icon-right {
    width: 26px;
    height: 26px;
    color: gray;
  }

  .select-container {
    width: 460px;
    @include desktop {
      width: 450px;
    }
    @include laptop {
      width: 400px;
    }
    @include tablet {
      width: 320px;
    }
    @include mobile-large {
      width: 320px;
    }
    @include mobile {
      width: 320px;
    }

    .access-text {
      width: 16%;
      @include desktop {
        width: 16%;
      }
      @include laptop {
        width: 20%;
      }
      @include tablet {
        width: 24%;
      }
      @include mobile-large {
        width: 20%;
      }
      @include mobile {
        width: 20%;
      }
      font-family: $font-title;
      font-size: $font-size-body;
      color: #000;
    }

    .text-field {
      width: 100%;
      background-color: #fff;
      font-family: $font-title;
      font-weight: bold !important;
      color: $primary-color !important;

      .css-i4bv87-MuiSvgIcon-root {
        display: none !important;
      }

      .MuiOutlinedInput-input {
        font-weight: bold !important;
        color: $primary-color !important;
      }

      .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: $font-title;
        font-weight: bold !important;
        color: $primary-color !important;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: #fff;
        font-weight: bold !important;
        color: $primary-color !important;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        font-weight: bold !important;
        color: $primary-color !important;
      }

      .MuiInputLabel-root.Mui-focused {
        font-weight: bold !important;
        color: $primary-color !important;
      }
    }
  }

  .user-name {
    font-size: $font-size-subtitle;
    color: gray;
  }

  .link-icon {
    font-size: 26px;
    color: $primary-color;
  }

  .btn-menu {
    padding: 0px 10px 0px 0px;
    height: 35px;

    .avatar-user {
      width: 35px;
      height: 35px;
    }

    .icon-menu {
      color: $primary-color;
    }

    .username-text {
      color: $primary-color;
      font-family: $font-body;
      font-size: $font-size-body;
      text-transform: none;
    }
  }
}
