@import "../themes.scss";

.page-container {
  .page-header {
    height: 40px;
    width: 100%;

    .page-title {
      font-family: $font-title;
      color: $primary-color;
      font-size: $font-size-title;
      font-weight: bold !important;
    }
    .page-subtitle {
      font-family: $font-title;
      color: gray;
      font-size: 13px;
    }

    .breadcrumbs-title {
      font-family: $font-title;
      color: black;
      font-size: $font-size-body;
    }

    .page-link-title {
      font-family: $font-title;
      color: $primary-color;
      font-size: $font-size-body;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .search-head-title {
    width: 100% !important;
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
    padding-bottom: 4px;
  }

  .btn-create {
    font-family: $font-title;
    font-size: $font-size-body;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 8px;
    padding: 6px 20px;
    height: 100%;
    text-transform: none;

    .icon-add {
      width: 18px;
      height: 18px;
    }
  }

  .btn-close {
    font-family: $font-title;
    font-size: $font-size-body;
    background-color: $background-delete !important;
    color: #fff;
    border-radius: 8px;
    padding: 6px 20px;
    height: 100%;
    text-transform: none;

    .icon-add {
      margin-right: 5px;
      width: 18px;
      height: 18px;
    }
  }

  // ====================================== report ================================

  .search-field {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    font-family: $font-title;

    .MuiOutlinedInput-input {
      border-radius: 10px;
      font-family: $font-title;
    }

    .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
      font-family: $font-title;
    }

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
      font-family: $font-title;
      border-radius: 6px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 6px;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 6px;
    }
  }

  .box-view-container {
    margin-top: 30px;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    .view-text-name {
      font-family: $font-title;
      font-size: $font-size-body;
      font-weight: bold;
    }

    .view-text-item {
      font-family: $font-title;
      font-size: $font-size-body;
    }
  }

  .body-container {
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    padding: 0px 0px 20px 0px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .table {
      width: 100%;
      display: table;
      overflow-x: auto;
      white-space: nowrap;
      border-collapse: separate;
      border-spacing: 0px 8px;

      .header-row {
        .header-title-start {
          border-radius: 6px 0px 0px 6px;
          padding: 10px 10px 10px 20px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          color: $primary-color;
          font-weight: bold;
        }

        .header-title {
          padding: 10px 10px 10px 20px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          color: $primary-color;
          font-weight: bold;
        }

        .header-title-end {
          border-radius: 0px 6px 6px 0px;
          padding: 10px 20px 10px 0px;
          border: none;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
        }

        .header-sticky-left {
          position: sticky;
          left: 0;
        }

        .header-sticky-right {
          position: sticky;
          right: 0;
        }
      }

      // =================== background white =================
      .body-row {
        background-color: #fff;
        cursor: pointer;

        .body-cell-start {
          border: none;
          font-size: $font-size-body;
          padding: 10px 10px 10px 20px;
          border-radius: 6px 0px 0px 6px;
        }

        .body-cell {
          border: none;
          font-size: $font-size-body;
          padding: 10px 10px 10px 20px;
        }

        .body-cell-end {
          border: none;
          font-size: $font-size-body;
          padding: 6px 20px 6px 0px;
          border-radius: 0px 6px 6px 0px;
        }

        .body-cell-sticky-left {
          position: sticky;
          left: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        .body-cell-sticky-right {
          position: sticky;
          right: 0;
          background-color: #fff;
          // box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }

        // ============================== For journal details =====================================
        .body-cell-start-details-journal {
          border: none;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          padding: 15px 10px 15px 20px;
          border-radius: 6px 0px 0px 6px;
        }
        .body-cell-details-journal {
          border: none;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          padding: 15px 10px;
        }
      }
    }
  }
}
