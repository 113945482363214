@import "../themes.scss";

.accounting-container {
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-image: url("../Assets/angkor-picture.png");
  background-attachment: fixed;
  background-size: 1400px;
  background-position: 45% 22%;

  .top-bar {
    background-color: #fff;
    padding: 0px 20px;

    .company-logo {
      width: 55px;
      height: 55px; 
    }
    .company-name {
      font-family: $font-title;
      font-size: $font-size-title;
      font-weight: bold;
      color: $primary-color;
    }

    .icon-right {
      width: 26px;
      height: 26px;
      color: gray;
    }

    .user-name {
      font-size: $font-size-subtitle;
      color: gray;
    }

    .link-icon {
      font-size: 26px;
      color: $primary-color;
    }

    .btn-menu {
      padding: 0px 10px 0px 0px;
      height: 35px;

      .avatar-user {
        width: 35px;
        height: 35px;
      }

      .icon-menu {
        color: $primary-color;
      }

      .username-text {
        color: $primary-color;
        font-family: $font-bayon;
        font-size: $font-size-body;
      }
    }
  }

  .body-container {
    width: 100%;
    height: 800px;

    .card-container {
      width: 400px;
      border-radius: 20px;
      padding: 30px 40px;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

      .text-header {
        font-family: $font-title;
        font-size: $font-size-body;
        text-align: center;
      }

      .text-body {
        font-family: $font-title;
        font-size: $font-size-subtitle;
        font-weight: bold;
        color: $primary-color;
        text-align: center;
      }

      .btn-next {
        background-color: $primary-color !important;
        color: #fff;
        font-family: $font-title;
        font-size: $font-size-body;
        border-radius: 20px;
      }
    }
  }
}
