@import "../themes.scss";

.dialog-container {
  .MuiPaper-root {
    border-radius: 10px !important;
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
    font-family: $font-title;
  }

  .close-icon {
    color: red;
  }

  .avater-image {
    width: 150px;
    height: 150px;
  }

  .image-title {
    font-family: $font-title;
    font-size: $font-size-body;
    color: $primary-color;
    text-align: center;
  }

  .field-title {
    font-family: $font-title;
    font-size: $font-size-body;
    color: $primary-color;
    font-weight: bold;
  }

  // ==================== User access ======================
  .department-title {
    width: 300px;
    padding: 4px 10px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  }

  .access-delete-container {
    width: 22px;
    height: 22px;
    border-radius: 6px;
    background-color: #fff !important;
    border: 1px solid $background-delete;
    .delete-icon {
      color: $background-delete;
      margin-top: 2px;
      // margin-bottom: 3px;
      font-size: 16px !important;
    }
  }

  .list-table-cell {
    padding: 8px 10px;
    font-family: $font-size-body;
    font-size: $font-size-body;
    color: $primary-color;
  }

  .list-table-body-cell {
    padding: 10px;
    font-family: $font-size-body;
    font-size: $font-size-body;
  }

  .addlist-icon {
    color: $primary-color;
    font-size: 24px;
  }

  .deletelist-icon {
    color: $background-delete;
    font-size: 24px;
  }

  .viewpw-icon {
    cursor: pointer;
  }

  .btn-create {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $primary-color !important;
    color: #fff;
    border-radius: 5px;
    font-family: $font-title;
    font-weight: bold;
    font-size: $font-size-body;
    text-transform: none;
  }

  .btn-delete {
    margin-top: 10px;
    padding: 8px 0px;
    background-color: $background-delete !important;
    color: #fff;
    border-radius: 5px;
    font-family: $font-title; 
    font-size: $font-size-body;
    font-weight: bold;
    text-transform: none;
  }
  .icon-button {
    width: 180px;
    height: 180px;
    margin-top: -10px;
    .company-logo {
      width: 80%;
      height: 80%;
    }
  }

  .invoice-title {
    font-size: 15px;
    font-family: $font-moul;
    font-weight: 200;
    color: $primary-color;
    text-align: center;
  }

  .invoice {
    font-size: 18px;
    font-family: $font-body;
    font-weight: bold;
    color: $primary-color;
    text-align: center;
    border-bottom: 1px solid $primary-color;
  }

  .date-title {
    font-size: $font-size-body;
    font-family: $font-body;
    text-align: right;
    color: $primary-color;
  }

  .body-title {
    font-size: 11px;
    font-family: $font-body;
    // color: $primary-color;
  }

  .body-title-bold {
    font-size: 14px;
    font-family: $font-body;
    font-weight: bold;
    text-align: center;
    // color: $primary-color;
  }

  .tab-container {
    border-collapse: collapse;
    // width: 100%;
    border: 1px solid $primary-color;
    .thead-table {
      border: 1px solid gray;
      font-size: 12px;
      font-family: $font-body;
    }
    .body-cell {
      border: 1px solid gray;
      text-align: center;
      font-size: 13px;
      font-family: $font-body;
      padding: 6px;
    }
    .footer-tab {
      border: 1px solid gray;
      text-align: center;
      font-size: 13px;
      padding: 6px;
      font-family: $font-moul;
    }
  }
  .footer-invoice {
    font-size: 12px;
    font-family: $font-moul;
    // color: #000;
    text-align: center;
  }

  .btn-print {
    background-color: $primary-color !important;
    font-size: $font-size-subtitle;
    color: #fff;
    text-align: center;
  }
  .table-head {
    height: 40px !important;
    border: none;

    .thead-title-start {
      padding: 10px;
      font-family: $font-body;
      font-size: $font-size-body;
      font-weight: bold;
      color: $primary-color;
      border: none;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }
    .thead-title {
      padding: 10px;
      color: $primary-color;
      border: none;
      font-family: $font-body;
      font-size: $font-size-body;
      font-weight: bold;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }
    .thead-title-end {
      color: $primary-color;
      padding: 10px;
      border: none;
      font-family: $font-body;
      font-size: $font-size-body;
      font-weight: bold;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    }
  }
  .total-field {
    justify-content: end;
    // margin-right: 30px;
    padding-right: 70px;
    .total-field-list {
      background-color: green;
      border-radius: 5px;
      padding-left: 10px;
      color: #fff;
      width: 19%;
      height: 40px;
      padding-top: 9px;
    }
    .total-result {
      width: 145px;
      background-color: green;
      border-radius: 6px;
      font-family: $font-title;
      color: #fff !important;

      .MuiOutlinedInput-input {
        color: #fff;
        border-radius: 10px;
        font-family: $font-body;
      }

      .css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
        font-family: $font-title;
        color: #fff !important;
      }

      .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
        border-color: green;
        font-family: $font-title;
        border-radius: 6px;
        color: #fff !important;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
        color: #fff !important;
        font-family: $font-title;
        border-radius: 6px;
      }

      .MuiInputLabel-root.Mui-focused {
        color: #fff !important;
        font-family: $font-title;
        border-radius: 6px;
      }
    }
  }
}
